<template>
  <v-container fluid>
    <v-card class="elevation-11">
      <v-card-title class="accent">
        <h3>Receipts</h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatStartDate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatEndDate"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                color="btn-large btn-block primary white--text"
                class="mr-2"
                @click="getReceipts"
                :disabled="!valid"
                >Get Receipts</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" elevation-11>
      <v-card-title>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-card>
          <v-card-title>TOTAL RECEIPTS</v-card-title>
          <v-data-table
            :headers="totalheaders"
            :items="totals"
            hide-default-footer
            class="elevation-1 mb-5"
          ></v-data-table>
        </v-card>

        <v-data-table
          :loading="loading"
          :search="search"
          :headers="headers"
          :items="receipts"
          item-key="invkey"
          class="elevation-1"
          loading-text="Retrieving data... please wait"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium @click="downloadReceipt(item)">mdi-download</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import api from "../../services/axiosconfig";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { aejImgData, iataImgData } from "../../services/logos";

export default {
  data() {
    return {
      valid: true,
      search: "",
      loading: false,
      headers: [
        { text: "Receipt No.", align: "start", value: "receiptnum" },
        { text: "Receipt Date", align: "start", value: "receiptdate" },
        { text: "Bank", align: "end", value: "bank" },
        { text: "Cash", align: "end", value: "cash" },
        { text: "Cheque", align: "end", value: "cheque" },
        { text: "Credit Card", align: "end", value: "credit" },
        { text: "Debit Card", align: "end", value: "debit" },
        { text: "AMEX", align: "end", value: "amex" },
        { text: "Voucher", align: "end", value: "voucher" },
        { text: "Misc.", align: "end", value: "misc" },
        { text: "Total", align: "end", value: "total" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      totalheaders: [
        { text: "Bank", align: "end", value: "totalbank" },
        { text: "Cash", align: "end", value: "totalcash" },
        { text: "Cheque", align: "end", value: "totalcheque" },
        { text: "Credit Card", align: "end", value: "totalcredit" },
        { text: "Debit Card", align: "end", value: "totaldebit" },
        { text: "AMEX", align: "end", value: "totalamex" },
        { text: "Voucher", align: "end", value: "totalvoucher" },
        { text: "Misc.", align: "end", value: "totalmisc" },
        { text: "Total", align: "end", value: "grandtotal" },
      ],

      receipts: [],
      pdfdata: [],
      totals: [],
      ledgerkey: "",

      totalBank: 0,
      totalCash: 0,
      totalCheque: 0,
      totalCredit: 0,
      totalDebit: 0,
      totalAmex: 0,
      totalVoucher: 0,
      totalMisc: 0,
      grandTotal: 0,

      startDateMenu: false,
      endDateMenu: false,

      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      caption: "Business & Leisure Travel Management",
      address: "No.35 St.John's Road, Isleworth, Middlesex TW7 6NY",
      telno: "Tel No. 020 8560 2458",
      email: "Email: accounts@aejtravels.co.uk",
      notes: [
        "IMPORTANT NOTICE : This is a special fare ticket and may be non refundable. Please check all conditions.Cancellations and changes may be restricted so check if in doubt. Please ensure that you have valid travel documents & visas for your journey. Please reconfirm all flights at least 72 hours before departure, failure to do so may result in cancellation of your booking. Thank you.",
      ],
    };
  },
  mounted() {
    this.getCurrentLedgerKey();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  computed: {
    formatStartDate() {
      return `${moment(this.startDate).format("DD-MM-YYYY")}`;
    },
    formatEndDate() {
      return `${moment(this.endDate).format("DD-MM-YYYY")}`;
    },
  },

  methods: {
    calcMinDate() {
      return moment()
        .subtract(localStorage.getItem("PERMITTED"), "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },

    calcMaxDate() {
      return moment().endOf("month").format("YYYY-MM-DD");
    },

    validateData() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.$toasted.global.optimus_error({
          message: "Start date must be on or before the end date.",
        });
        return false;
      } else {
        return true;
      }
    },

    dateFormat(dateString) {
      return moment(new Date(dateString)).format("DD-MM-YYYY");
    },

    getCurrentLedgerKey() {
      this.ledgerKey = localStorage.getItem("LEDG_KEY");
    },

    async getReceipts() {
      if (this.validateData()) {
        try {
          this.receipts = [];
          this.pdfdata = [];
          this.totals = [];
          this.totalBank = 0;
          this.totalCash = 0;
          this.totalCheque = 0;
          this.totalCredit = 0;
          this.totalDebit = 0;
          this.totalAmex = 0;
          this.totalVoucher = 0;
          this.totalMisc = 0;
          this.grandTotal = 0;
          this.loading = true;

          const response = await api.post("/aejmis/api/receipts", {
            startdate: this.startDate,
            enddate: this.endDate,
            ledgerkey: this.ledgerKey,
          });
          this.pdfdata = response.data;
          this.pdfdata.map(({ receiptnum, receipt }) => {
            this.receipts.push({
              receiptnum: receiptnum,
              receiptdate: this.dateFormat(receipt[0].receiptdate),
              bank: this.calcBankTotal(receipt),
              cash: this.calcCashTotal(receipt),
              cheque: this.calcChequeTotal(receipt),
              credit: this.calcCreditTotal(receipt),
              debit: this.calcDebitTotal(receipt),
              amex: this.calcAmexTotal(receipt),
              voucher: this.calcVoucherTotal(receipt),
              misc: this.calcMiscTotal(receipt),
              total: this.calcRcptTotal(receipt),
            });
          });

          this.totals.push({
            totalbank: parseFloat(this.totalBank).toFixed(2),
            totalcash: parseFloat(this.totalCash).toFixed(2),
            totalcheque: parseFloat(this.totalCheque).toFixed(2),
            totalcredit: parseFloat(this.totalCredit).toFixed(2),
            totaldebit: parseFloat(this.totalDebit).toFixed(2),
            totalamex: parseFloat(this.totalAmex).toFixed(2),
            totalvoucher: parseFloat(this.totalVoucher).toFixed(2),
            totalmisc: parseFloat(this.totalMisc).toFixed(2),
            grandtotal: parseFloat(this.grandTotal).toFixed(2),
          });

          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
          this.$toasted.global.optimus_error({
            message: "Could not retrieve information. Try after sometime.",
          });
        }
      }
    },

    calcBankTotal(receipt) {
      let bank = 0;
      receipt.forEach((item) => {
        bank = bank + item.amountpaidtype1;
      });
      this.totalBank = this.totalBank + bank;
      return parseFloat(bank).toFixed(2);
    },

    calcCashTotal(receipt) {
      let cash = 0;
      receipt.forEach((item) => {
        cash = cash + item.amountpaidtype2;
      });
      this.totalCash = this.totalCash + cash;
      return parseFloat(cash).toFixed(2);
    },

    calcChequeTotal(receipt) {
      let cheque = 0;
      receipt.forEach((item) => {
        cheque = cheque + item.amountpaidtype3;
      });
      this.totalCheque = this.totalCheque + cheque;
      return parseFloat(cheque).toFixed(2);
    },

    calcCreditTotal(receipt) {
      let credit = 0;
      receipt.forEach((item) => {
        credit = credit + item.amountpaidtype4;
      });
      this.totalCredit = this.totalCredit + credit;
      return parseFloat(credit).toFixed(2);
    },

    calcDebitTotal(receipt) {
      let debit = 0;
      receipt.forEach((item) => {
        debit = debit + item.amountpaidtype5;
      });
      this.totalDebit = this.totalDebit + debit;
      return parseFloat(debit).toFixed(2);
    },

    calcAmexTotal(receipt) {
      let amex = 0;
      receipt.forEach((item) => {
        amex = amex + item.amountpaidtype6;
      });
      this.totalAmex = this.totalAmex + amex;
      return parseFloat(amex).toFixed(2);
    },

    calcVoucherTotal(receipt) {
      let voucher = 0;
      receipt.forEach((item) => {
        voucher = voucher + item.amountpaidtype7;
      });
      this.totalVoucher = this.totalVoucher + voucher;
      return parseFloat(voucher).toFixed(2);
    },

    calcMiscTotal(receipt) {
      let misc = 0;
      receipt.forEach((item) => {
        misc = misc + item.amountpaidtype8;
      });
      this.totalMisc = this.totalMisc + misc;
      return parseFloat(misc).toFixed(2);
    },

    calcRcptTotal(receipt) {
      let total = 0;
      receipt.forEach((item) => {
        total =
          total +
          item.amountpaidtype1 +
          item.amountpaidtype2 +
          item.amountpaidtype3 +
          item.amountpaidtype4 +
          item.amountpaidtype5 +
          item.amountpaidtype6 +
          item.amountpaidtype7 +
          item.amountpaidtype8;
      });
      this.grandTotal = this.grandTotal + total;
      return parseFloat(total).toFixed(2);
    },

    downloadReceipt(item) {
      const selectedIndex = this.receipts.indexOf(item);
      const selectedItem = this.pdfdata[selectedIndex];

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      var pageWidth = doc.internal.pageSize.getWidth();
      var pageHeight = doc.internal.pageSize.getHeight();

      doc.addImage(aejImgData, "png", 10, 10);
      doc.addImage(iataImgData, "png", pageWidth - 47, 7);

      doc.setFontSize(12).text(this.caption, 10, 42);

      let addressX = pageWidth - (doc.getTextWidth(this.address) - 6);
      doc.setFontSize(10).text(this.address, addressX, 32);

      let telnoX = pageWidth - (doc.getTextWidth(this.telno) + 11);
      doc.setFontSize(10).text(this.telno, telnoX, 37);

      let emailX = pageWidth - (doc.getTextWidth(this.email) + 11);
      doc.setFontSize(10).text(this.email, emailX, 42);

      doc.setLineWidth(0.01).line(10, 46, 200, 46);

      const header = "RECEIPT";
      let headerX = (pageWidth - doc.getTextWidth(header)) / 2 - 10;
      doc.setFont("helvetica").setFontSize(16).text(header, headerX, 53);

      doc.autoTable({
        body: [
          [
            {
              content: `${localStorage.getItem(
                "USER_NAME"
              )}\n${localStorage.getItem("ADDRESS_1")}\n${localStorage.getItem(
                "ADDRESS_2"
              )}\n${localStorage.getItem("ADDRESS_3")}\n${localStorage.getItem(
                "PIN_CODE"
              )} \n`,
              styles: {
                halign: "left",
                fontSize: 10,
              },
            },

            {
              content: `Receipt No: ${
                selectedItem.receiptnum
              }\nReceipt Date: ${this.dateFormat(
                selectedItem.receipt[0].receiptdate
              )}`,
              styles: {
                halign: "right",
                fontSize: 10,
              },
            },
          ],
        ],
        margin: { left: 10, top: 55 },
        theme: "plain",
      });

      // Receipt Details
      let details = [];
      let totals = [];
      let totalBank = 0;
      let totalCash = 0;
      let totalCheque = 0;
      let totalCredit = 0;
      let totalDebit = 0;
      let totalAmex = 0;
      let totalVoucher = 0;
      let totalMisc = 0;
      let totalGrand = 0;
      selectedItem.receipt.forEach((item) => {
        let bank = 0;
        let cash = 0;
        let cheque = 0;
        let credit = 0;
        let debit = 0;
        let amex = 0;
        let voucher = 0;
        let misc = 0;
        let total = 0;
        let invamount = 0;

        item.Invoice.costing.forEach((cost) => {
          invamount = invamount + cost.quantity * cost.gross;
        });

        bank = bank + item.amountpaidtype1;
        cash = cash + item.amountpaidtype2;
        cheque = cheque + item.amountpaidtype3;
        credit = credit + item.amountpaidtype4;
        debit = debit + item.amountpaidtype5;
        amex = amex + item.amountpaidtype6;
        voucher = voucher + item.amountpaidtype7;
        misc = misc + item.amountpaidtype8;
        total =
          total +
          item.amountpaidtype1 +
          (item.amountpaidtype2 +
            item.amountpaidtype3 +
            item.amountpaidtype4 +
            item.amountpaidtype5 +
            item.amountpaidtype6 +
            item.amountpaidtype7 +
            item.amountpaidtype8);

        totalBank = totalBank + bank;
        totalCash = totalCash + cash;
        totalCheque = totalCheque + cheque;
        totalCredit = totalCredit + credit;
        totalDebit = totalDebit + debit;
        totalAmex = totalAmex + amex;
        totalVoucher = totalVoucher + voucher;
        totalMisc = totalMisc + misc;
        totalGrand = totalGrand + total;

        details.push(
          new Array(
            {
              content: this.dateFormat(item.Invoice.invoicedate),
              styles: {
                halign: "center",
              },
            },
            {
              content: item.Invoice.invoicenum,
              styles: {
                halign: "left",
              },
            },
            {
              content:
                item.Invoice.firstname.trim() +
                " " +
                item.Invoice.lastname.trim(),
              styles: {
                halign: "left",
              },
            },
            {
              content: item.Invoice.locator,
              styles: {
                halign: "left",
              },
            },
            {
              content: invamount.toFixed(2),
              styles: {
                halign: "right",
              },
            },
            {
              content: this.dateFormat(item.Invoice.depdate),
              styles: {
                halign: "center",
              },
            },
            {
              content: total.toFixed(2),
              styles: {
                halign: "right",
              },
            }
          )
        );
      });

      totals.push(
        new Array(
          {
            content: totalBank.toFixed(2),
            styles: {
              halign: "right",
            },
          },

          {
            content: totalCash.toFixed(2),
            styles: {
              halign: "right",
            },
          },
          {
            content: totalCheque.toFixed(2),
            styles: {
              halign: "right",
            },
          },
          {
            content: totalCredit.toFixed(2),
            styles: {
              halign: "right",
            },
          },
          {
            content: totalDebit.toFixed(2),
            styles: {
              halign: "right",
            },
          },
          {
            content: totalAmex.toFixed(2),
            styles: {
              halign: "right",
            },
          },
          {
            content: totalVoucher.toFixed(2),
            styles: {
              halign: "right",
            },
          },
          {
            content: totalMisc.toFixed(2),
            styles: {
              halign: "right",
            },
          },
          {
            content: totalGrand.toFixed(2),
            styles: {
              halign: "right",
            },
          }
        )
      );

      doc.autoTable({
        head: [
          [
            {
              content: "Bank (GBP)",
              styles: {
                halign: "right",
              },
            },
            {
              content: "Cash (GBP)",
              styles: {
                halign: "right",
              },
            },
            {
              content: "Cheque (GBP)",
              styles: {
                halign: "right",
              },
            },
            {
              content: "Credit (GBP)",
              styles: {
                halign: "right",
              },
            },
            {
              content: "Debit (GBP)",
              styles: {
                halign: "right",
              },
            },
            {
              content: "AMEX (GBP)",
              styles: {
                halign: "right",
              },
            },
            {
              content: "Voucher (GBP)",
              styles: {
                halign: "right",
              },
            },
            {
              content: "Misc. (GBP)",
              styles: {
                halign: "right",
              },
            },
            {
              content: "Total (GBP)",
              styles: {
                halign: "right",
              },
            },
          ],
        ],

        body: totals,
        theme: "plain",
        tableLineWidth: 0.1,
        tableLineColor: [0, 0, 0],
      });

      doc.autoTable({
        head: [
          [
            {
              content: "Invoice Date",
              styles: {
                halign: "left",
              },
            },
            {
              content: "Invoice No.",
              styles: {
                halign: "right",
              },
            },
            {
              content: "Lead Name",
              styles: {
                halign: "left",
              },
            },
            {
              content: "Locator",
              styles: {
                halign: "left",
              },
            },
            {
              content: "Invoice Value",
              styles: {
                halign: "right",
              },
            },
            {
              content: "Dep Date",
              styles: {
                halign: "center",
              },
            },
            {
              content: "This Payment",
              styles: {
                halign: "right",
              },
            },
          ],
        ],
        body: details,
        theme: "plain",
        tableLineWidth: 0.1,
        tableLineColor: [0, 0, 0],
      });

      doc.setLineWidth(0.01).line(10, pageHeight - 30, 200, pageHeight - 30);

      doc
        .setFont("helvetica")
        .setFontSize(8)
        .text(this.notes, 10, pageHeight - 25, {
          align: "justify",
          maxWidth: "190",
        });

      doc.setLineWidth(0.01).line(10, pageHeight - 15, 200, pageHeight - 15);

      // Page Numbers and Total Pages
      const pageCount = doc.internal.getNumberOfPages();

      for (var i = 1; i <= pageCount; i++) {
        const pageNum = `Page ${String(i)} of ${String(pageCount)}`;
        let pageX = pageWidth - (doc.getTextWidth(pageNum) + 12);
        doc.setPage(i);
        doc
          .setFont("helvetica")
          .setFontSize(9)
          .text(pageNum, pageX, pageHeight - 10);
      }

      // Footer Content
      const footer = "VAT No. 859 0048 13";
      doc
        .setFont("helvetica")
        .setFontSize(10)
        .text(footer, 10, pageHeight - 10);

      // doc.save(`AEJ-RECEIPT-${selectedItem.receiptnum}.pdf`);
      window.open(doc.output("bloburl"), "_blank");
    },
  },
};
</script>
